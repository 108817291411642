

































import {
  Component,
  Prop,
  Mixins,
} from 'vue-property-decorator';

import DoubtsLoading from '@/components/Doubts/ListDoubts/components/Doubts/DoubtsLoading.vue';
import Doubt from '@/components/Doubts/ListDoubts/Doubts.vue';
import { Doubt as IDoubt } from '@/globalInterfaces/Doubts';

import mixinDoubts from '@/mixins/doubts';

@Component({
  components: {
    DoubtsLoading,
    Doubt,
  },
})
export default class LastSendDoubt extends Mixins(mixinDoubts) {
  @Prop() doubt!: IDoubt;
  @Prop() isLoading!: boolean;

  handleSendDoubt() {
    this.$emit('onSendDoubt');
  }
}
